<script>
import { Card } from 'ant-design-vue'
import LineChart from '../LineChart'
import LineChartTwo from '../LineChartTwo'
import { getAction } from '@/command/netTool'
export default {
  data() {
    return {
      waitOperate: {},
      refundCount: {},
      visitUser: {},
      orderCount: {},
      orderSale: {},
      sumOrder: {},
      dateFlag5: 1,
      dateFlag1: 1,
      dateFlag2: 2,
      dateFlag3: 1,
      dateFlag4: 2
    }
  },
  mounted() {
    // 售后处理、意见反馈、结算审核
    getAction('/api/base/farmIndexCount/waitOperate').then(res => {
      if (res.code == 200) {
        this.waitOperate = res.data
      } else {
        this.$message.error(res.msg)
      }
    })
    this.handleRefundCount()
    this.handleVisitUser()
    this.handleOrderCount()
    this.handleOrderSale()
    this.handleSumOrder()
  },
  methods: {
    // 退款处理
    handleRefundCount() {
      getAction('/api/base/farmIndexCount/refundCount?dateFlag=' + this.dateFlag1).then(res => {
        if (res.code == 200) {
          this.refundCount = res.data
        } else {
          this.$message.error(res.msg)
        }
      })
    },
    // 访问用户
    handleVisitUser() {
      getAction('/api/base/farmIndexCount/visitUser?dateFlag=' + this.dateFlag3).then(res => {
        if (res.code == 200) {
          this.visitUser = res.data
        } else {
          this.$message.error(res.msg)
        }
      })
    },
    // 订单量
    handleOrderCount() {
      getAction('/api/base/farmIndexCount/orderCount?dateFlag=' + this.dateFlag4).then(res => {
        if (res.code == 200) {
          this.orderCount = res.data
        } else {
          this.$message.error(res.msg)
        }
      })
    },
    // 销售额
    handleOrderSale() {
      getAction('/api/base/farmIndexCount/orderSale?dateFlag=' + this.dateFlag2).then(res => {
        if (res.code == 200) {
          this.orderSale = res.data
        } else {
          this.$message.error(res.msg)
        }
      })
    },
    // 交易概况
    handleSumOrder() {
      getAction('/api/base/farmIndexCount/sumOrder?dateFlag=' + this.dateFlag5).then(res => {
        if (res.code == 200) {
          this.sumOrder = res.data
        } else {
          this.$message.error(res.msg)
        }
      })
    },
    // 退款处理 待发货 店铺评价
    renderTop() {
      const data = [
        {
          name: '退款处理',
          value: this.waitOperate.afterSaleCount ?? 0,
          onClick: () => this.$router.push('/orderProcessing/refund')
        },
        {
          name: '待发货',
          value: this.waitOperate.receivingCount ?? 0,
          onClick: () => this.$router.push('/orderProcessing/localProducts?status=2')
        },
        {
          name: '意见反馈',
          value: this.waitOperate.feedbackCount ?? 0,
          onClick: () => this.$router.push('/audit/feedback')
        },
        {
          name: '结算审核',
          value: this.waitOperate.settmentCount ?? 0,
          onClick: () => this.$router.push('/audit/settlementAudit')
        }
      ]
      return (
        <div class="home-top">
          {data.map(e => {
            return (
              <div class="home-top-item" onClick={() => e.onClick()}>
                <div>{e.value}</div>
                <div>{e.name}</div>
              </div>
            )
          })}
        </div>
      )
    },
    handleChange() {
      console.log('请重新选择模块按钮！！！')
    },
    renterBtn(index) {
      let aIndex = Number.parseInt(this['dateFlag' + index])
      return (
        <a-radio-group
          class="btn-group"
          value={aIndex}
          onChange={e => {
            this['dateFlag' + index] = e.target.value
            this[
              [
                'handleChange',
                'handleRefundCount',
                'handleOrderSale',
                'handleVisitUser',
                'handleOrderCount',
                'handleSumOrder'
              ][index]
            ]()
          }}
        >
          <a-radio-button value={1}>日</a-radio-button>
          <a-radio-button value={2}>周</a-radio-button>
          <a-radio-button value={3}>月</a-radio-button>
          <a-radio-button value={4}>总</a-radio-button>
        </a-radio-group>
      )
    },
    // 退款处理、销售额、访问用户
    returnUser() {
      return (
        <div class="card_box">
          <Card class="car_box-one">
            <div class="top">
              <span>退款处理</span>
              <span class="span-box">{this.renterBtn(1)}</span>
            </div>
            <div class="hr"></div>
            <div class="text-box">{this.refundCount.totalAmount}</div>
            <div class="float_box">
              <div class="float_box-left">
                日同比 {this.refundCount.dailyAddFlag == 1 ? '+' : '-'}
                {this.refundCount.dailyAddRate}% &nbsp;
                <i
                  class="iconfont icon-jiantouarrow492"
                  style={{
                    color: this.refundCount.dailyAddFlag == 1 ? 'red' : 'green',
                    fontSize: '10px'
                  }}
                ></i>
              </div>
              <div class="float_box-right">
                周同比{this.refundCount.weekAddFlag == 1 ? '+' : '-'}
                {this.refundCount.weekAddRate}% &nbsp;
                <i
                  class="iconfont icon-jiantouarrow492"
                  style={{
                    color: this.refundCount.weekAddFlag == 1 ? 'red' : 'green',
                    fontSize: '10px'
                  }}
                ></i>
              </div>
            </div>
            <div class="hr" style={{ marginTop: '15px' }}></div>
            <div class="bottom">
              <span>总访问量</span>
              <span>{this.refundCount.totalAmount}</span>
            </div>
          </Card>
          <Card class="car_box-two">
            <div class="top">
              <span>销售额</span>
              <span class="span-box">{this.renterBtn(2)}</span>
            </div>
            <div class="hr"></div>
            <div class="text-box">{this.orderSale.currentAmount}</div>
            <div class="float_box indexIdNO1">
              {this.orderSale.list && <LineChart style="margin-top: -9.5%" data={this.orderSale.list} indexId={1} />}
            </div>
            <div class="hr" style={{ marginTop: '15px' }}></div>
            <div class="bottom">
              <span>总销售额</span>
              <span>{this.orderSale.totalAmount}</span>
            </div>
          </Card>
          <Card class="car_box-three">
            <div class="top">
              <span>访问用户</span>
              <span class="span-box">{this.renterBtn(3)}</span>
            </div>
            <div class="hr"></div>
            <div class="text-box">{this.visitUser.totalCount}位</div>
            <div class="float_box">
              <div class="img_box">
                {(this.visitUser.heagImgList ? this.visitUser.heagImgList : []).map((e, i) => {
                  if (i <= 4) {
                    return <a-avatar size="large" src={e} icon="user" class="avatar" />
                  } else if (i == 5) {
                    return (
                      <a-avatar class="avatar div_av" size="large">
                        +{this.visitUser.heagImgList.length - 5}
                      </a-avatar>
                    )
                  }
                })}
              </div>
            </div>
            <div class="hr" style={{ marginTop: '15px' }}></div>
            <div class="bottom">
              <span>总访问数</span>
              <span>{this.visitUser.totalCount}位</span>
            </div>
          </Card>
        </div>
      )
    },
    // 交易概况、订单量、访问设备
    returnSetNumber() {
      return (
        <div class="card_box2">
          <Card class="car_box-one">
            <div class="top">
              <span>交易概况</span>
              <span class="span-box">{this.renterBtn(5)}</span>
            </div>
            <div class="hr"></div>
            <div class="fw fw_one">
              <span class="a">访问人数</span>
              <span class="b">{this.sumOrder.totalVisit}</span>
            </div>
            <div class="hr"></div>
            <div class="fw fw_two">
              <aside>
                <span class="a">下单买家</span>
                <span class="b">{this.sumOrder.orderCount}</span>
              </aside>
              <aside>
                <span class="a">下单金额</span>
                <span class="b">{this.sumOrder.orderAmount}</span>
              </aside>
            </div>
            <div class="hr"></div>
            <div class="fw fw_two">
              <aside>
                <span class="a">支付买家</span>
                <span class="b">{this.sumOrder.payCount}</span>
              </aside>
              <aside>
                <span class="a">支付金额</span>
                <span class="b">{this.sumOrder.payAmount}</span>
              </aside>
            </div>
          </Card>
          <Card class="car_box-two">
            <div class="top">
              <span>订单量</span>
              <div class="float_box"></div>
              <span class="span-box">{this.renterBtn(4)}</span>
            </div>
            <div class="hr"></div>
            <div class="text-box">{this.orderCount.orderCount}</div>
            <div class="float_box indexIdNO2">
              {this.orderCount.list && <LineChartTwo data={this.orderCount.list} indexId={2} />}
            </div>
          </Card>
          <Card class="car_box-three">
            {
              //   <div style="display:none">
              //   <div class="top">
              //     <span>访问设备</span>
              //     <span class="span-box">
              //       <a-radio-group class="btn-group" onChange={this.handleChange}>
              //         <a-radio-button value={1}>日</a-radio-button>
              //         <a-radio-button value={2}>月</a-radio-button>
              //         <a-radio-button value={3}>周</a-radio-button>
              //         <a-radio-button value={4}>总</a-radio-button>
              //       </a-radio-group>
              //     </span>
              //   </div>
              //   <div class="hr"></div>
              //   <div class="fw fwl">
              //     <aside>
              //       <span class="iconfont icon-ios"></span>
              //       <span>IOS</span>
              //     </aside>
              //     <span style={{ fontSize: '20px', color: '#515A6E' }}>
              //       23,456
              //     </span>
              //   </div>
              //   <div class="hr"></div>
              //   <div class="fw fwl">
              //     <aside>
              //       <span class="iconfont icon-anzhuo"></span>
              //       <span>Anditor</span>
              //     </aside>
              //     <span style={{ fontSize: '20px', color: '#515A6E' }}>
              //       23,456
              //     </span>
              //   </div>
              //   <div class="hr"></div>
              //   <div class="fw fwl">
              //     <aside>
              //       <span class="iconfont icon-h5"></span>
              //       <span>H5</span>
              //     </aside>
              //     <span style={{ fontSize: '20px', color: '#515A6E' }}>
              //       23,456
              //     </span>
              //   </div>
              // </div>
            }
          </Card>
        </div>
      )
    }
  },
  render() {
    return <div class="home-main">{[this.renderTop(), this.returnUser(), this.returnSetNumber()]}</div>
  }
}
</script>
<style lang="less" scoped>
.img_box {
  width: 100%;
  .avatar {
    &:nth-child(n + 2) {
      margin-left: -15px;
    }
  }
  .div_av {
    background-color: rgba(253, 227, 207, 1);
    border-color: rgba(253, 227, 207, 1);
    font-size: 12px;
    color: #f6741b;
  }
}
/deep/.home-top-item {
  div {
    &:nth-of-type(2) {
      font-size: 14px;
      color: #515a6e;
    }
  }
}
.btn-group {
  /deep/ .ant-radio-button-wrapper {
    padding: 0 12px !important;

    -moz-box-shadow: none;
    -webkit-box-shadow: none;
    box-shadow: none;
    font-family: 'PingFang SC Normal', 'PingFang SC';
    font-weight: 400;
    font-style: normal;
    font-size: 14px;
    box-sizing: border-box;
    border-width: 1px;
    border-style: solid;
  }
}
/deep/.ant-radio-button-wrapper.ant-radio-button-wrapper-checked {
  background-color: rgba(44, 140, 240, 1);
  border-color: rgba(44, 140, 240, 1);
  color: #ffffff;
  border-radius: 4px;
  border-top-right-radius: 0px;
  border-bottom-right-radius: 0px;
}
.card_box {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  margin-top: 10px;
  height: 220px;

  .car_box-one,
  .car_box-two,
  .car_box-three {
    width: 27%;
    height: 100%;

    .bottom {
      display: flex;
      justify-content: space-between;
      align-items: center;
      width: 100%;
      height: 50px;
      font-size: 16px;
      color: #515a6e;
    }

    .float_box {
      display: flex;
      justify-content: center;
      align-items: flex-start;
      margin-top: 10px;
      height: 26px;

      div {
        flex: 1;
      }
    }

    .text-box {
      font-size: 36px;
      margin-top: 10px;
      color: #515a6e;
    }

    .hr {
      width: 113%;
      height: 1px;
      margin-left: -24px;
      background-color: #eee;
    }

    .top {
      display: flex;
      justify-content: space-between;
      align-items: center;
      width: 100%;
      height: 50px;
      margin-top: -24px;

      .span-box {
        display: inline-block;
        min-width: 20px;
      }
    }
  }
  .car_box-two {
    width: 45%;
    height: 100%;

    .float_box {
      height: 26px;
    }
  }
  .car_box-three {
    width: 27%;
    height: 100%;

    .float_box {
      display: block;

      .avatar {
        margin-top: -8px;
      }
    }

    .top {
      display: flex;
      justify-content: space-between;
      align-items: center;
      width: 100%;
      height: 50px;
      margin-top: -24px;

      .span-box {
        display: inline-block;
        min-width: 20px;
      }
    }
  }
}
.card_box2 {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  margin-top: 10px;
  height: 462px;

  .car_box-one {
    width: 27%;
    height: 100%;

    .fw {
      width: 100%;
      height: 135px;
    }

    .fw_one {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: flex-start;
      height: 135px;

      .a {
        font-size: 14px;
        color: #a0a0a0;
      }
      .b {
        font-size: 20px;
        color: #515a6e;
      }
    }

    .fw_two {
      display: flex;
      justify-content: space-between;
      align-items: center;
      height: 135px;

      aside {
        flex: 1;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: flex-start;
      }

      .a {
        font-size: 14px;
        color: #a0a0a0;
      }
      .b {
        font-size: 20px;
        color: #515a6e;
      }
    }

    .hr {
      width: 113%;
      height: 1px;
      margin-left: -24px;
      background-color: #eee;
    }

    .top {
      display: flex;
      justify-content: space-between;
      align-items: center;
      width: 106%;
      height: 50px;
      margin-top: -24px;

      .span-box {
        display: inline-block;
        width: 44%;
        height: 32px;
      }
    }
  }
  .car_box-two {
    width: 45%;
    height: 100%;

    .text-box {
      font-size: 36px;
      margin-top: 10px;
      color: #515a6e;
    }

    .bottom {
      display: flex;
      justify-content: space-between;
      align-items: center;
      width: 100%;
      height: 50px;
      font-size: 16px;
      color: #515a6e;
    }

    .float_box {
      height: 278px;
    }

    .hr {
      width: 113%;
      height: 1px;
      margin-left: -24px;
      background-color: #eee;
    }

    .top {
      display: flex;
      justify-content: space-between;
      align-items: center;
      width: 100%;
      height: 50px;
      margin-top: -24px;

      .span-box {
        display: inline-block;
        min-width: 20px;
        margin-left: -12%;
      }
    }
  }
  .car_box-three {
    width: 27%;
    height: 100%;

    .fw {
      width: 100%;
      height: 135px;
    }

    .fwl {
      display: flex;
      justify-content: space-between;
      align-items: center;
      width: 100%;

      aside {
        display: flex;
        align-items: center;

        span {
          &:nth-of-type(1) {
            color: #ddd;
            font-size: 42px;
            margin-right: 10px;
          }
          &:nth-of-type(2) {
            font-size: 14px;
            color: #a0a0a0;
          }
        }
      }
    }

    .hr {
      width: 113%;
      height: 1px;
      margin-left: -24px;
      background-color: #eee;
    }

    .top {
      display: flex;
      justify-content: space-between;
      align-items: center;
      width: 106%;
      height: 50px;
      margin-top: -24px;

      .span-box {
        display: inline-block;
        width: 44%;
        height: 32px;
      }
    }
  }
}
.home-top {
  display: flex;
  align-items: center;
  height: 101px;
  width: 100%;
  background: white;
  .home-top-item {
    display: flex;
    flex: 1;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    position: relative;
    font-family: 'PingFang SC Normal', 'PingFang SC';
    font-weight: 500;
    font-style: normal;
    font-size: 20px;
    color: #515a6e;
    &:not(:last-child)::after {
      position: absolute;
      right: 0px;
      top: 0px;
      bottom: 0px;
      width: 1px;
      background: rgb(229, 231, 233);
      content: '';
    }
  }
}
</style>
