<script>
import moment from 'moment'
const DataSet = require('@antv/data-set')
export default {
  props: {
    data: {
      type: Array,
      default: () => {
        return []
      }
    }
  },
  mounted() {
    let data = this.data.map(e => {
      return {
        amount: Number(e.amount),
        date: moment(e.date).format('MM-DD')
      }
    })

    let ds = new DataSet()
    let dv = ds.createView().source(data)
    // fold 方式完成了行列转换，如果不想使用 DataSet 直接手工转换数据即可
    dv.transform({
      type: 'fold',
      fields: ['amount'],
      value: '销售额'
    })
    // eslint-disable-next-line no-undef
    let chart = new G2.Chart({
      container: this.$refs.main,
      forceFit: true,
      height: 175
    })
    chart.source(dv, {
      month: {
        range: [0, 1]
      }
    })
    chart.axis('销售额', {
      title: null,
      label: null,
      line: null,
      tickLine: null,
      grid: null
    })
    chart
      .line()
      .position('date*销售额')
      .color('rgb(2, 204, 103)')
      .shape('smooth')
    chart.render()
  },
  render() {
    return <div ref="main" style={{ width: '100%', height: '200px' }} />
  }
}
</script>
