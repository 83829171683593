<script>
const DataSet = require('@antv/data-set')
import moment from 'moment'

export default {
  props: {
    data: {
      type: Array,
      default: () => {
        return []
      }
    }
  },
  mounted() {
    let data = this.data.map(e => {
      return {
        count: Number(e.count),
        date: moment(e.date).format('MM-DD')
      }
    })

    let ds = new DataSet()
    let dv = ds.createView().source(data)
    // fold 方式完成了行列转换，如果不想使用 DataSet 直接手工转换数据即可
    dv.transform({
      type: 'fold',
      fields: ['count'],
      value: '订单量'
    })
    // eslint-disable-next-line no-undef
    let chart = new G2.Chart({
      container: this.$refs.mainTwo,
      forceFit: true,
      height: 390
    })
    chart.source(dv, {
      month: {
        range: [0, 1]
      }
    })
    chart.axis('订单量', {
      title: null,
      label: null,
      tickLine: null,
      grid: null,
      line: null
    })

    chart
      .line()
      .position('date*订单量')
      .color('rgb(45, 140, 240)')
      .shape('smooth')
    chart.render()
  },
  render() {
    return <div ref="mainTwo" style={{ width: '100%', height: '200px' }} />
  }
}
</script>
